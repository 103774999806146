<template>
  <div class="dapp-item">
    <div class="w">
      <!-- DAPP应用 -->
      <!-- <div class="t1">
        {{ $t("dapp.box1T1") }}
      </div> -->
      <div class="t2">
        <!-- 由PSC提供支持的工具和服务 -->
        {{ $t("dapp.box1T2") }}
      </div>
      <div class="p pfont">
        <!-- 去中心化应用 (dapp)
        是在去中心化网络上构建的应用程序，结合了智能合约和前端用户界面。 -->
        {{ $t("dapp.box1P") }}
      </div>
      <div class="butBox">
        <a href="#dapp">
          <div class="but1" @click="uti.goPath(store.link.dapp.box1Bt2)" >
            <!-- 探索DAPP -->
            {{ $t("dapp.box1But1") }}
          </div>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import Store from "../../store";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
};
</script>

<style lang="less" scoped>
.dapp-item {
  height: 100vh;
  background: linear-gradient(0deg, #dae6f2, #edf4fc);
  .w {
    // background-color: pink;
    height: 100vh;
    background-image: url("../../assets/images/v4/dapp/box1.png");
    background-position: right, bottom;
    background-repeat: no-repeat;
    padding-top: 160px;
    box-sizing: border-box;
    .t1 {
      height: 29px;
      font-size: 30px;
      font-weight: 400;
      color: #333333;
    }
    .t2 {
      width: 888px;
      // margin-top: 65px;
      height: 159px;
      font-size: 70px;
      font-weight: bold;
      color: #333333;
      line-height: 92px;
    }
    .p {
      width: 622px;
      margin-top: 39px;
      height: 66px;
      font-size: 24px;
      font-weight: 500;
      color: #666666;
    }
    .butBox {
      display: flex;
      margin-top: 128px;
      text-align: center;
      .but1 {
        display: inline-block;
        padding: 0 42px;
        height: 54px;
        line-height: 54px;
        background: linear-gradient(-90deg, #10c1fc, #3f65f9);
        box-shadow: 0px 5px 20px 0px rgba(184, 205, 233, 0.5);
        border-radius: 27px;
        font-size: 20px;
        font-weight: 400;
        color: #ffffff;
        margin-right: 50px;
      }
      .but2 {
        display: inline-block;
        padding: 0 42px;
        height: 54px;
        line-height: 54px;
        box-shadow: 0px 5px 20px 0px rgba(184, 205, 233, 0.5);
        border-radius: 27px;
        font-size: 20px;
        font-weight: 400;
        color: #ffffff;
        font-weight: 400;
        color: #333333;
        background: linear-gradient(-90deg, #10c1fc 0%, #3f65f9 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        position: relative;
      }
      .but2::after {
        content: "";
        position: absolute;
        left: 0;
        border-radius: 27px;
        background-color: #fff;
        z-index: -1;
        width: 100%;
        height: 54px;
      }
    }
  }
}
</style>
