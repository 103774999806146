<template>
  <div class="dapp-box">
    <div class="w" id="dapp">
      <div class="title">
        <!-- DAPP应用 -->
        {{ $t("dapp.box2T") }}
      </div>
      <div class="gang"></div>
      <div class="subTitle">
        <!-- 一些polysmartchain.com团队现在喜欢的去中心化应用。下面是更多的去中心化应用。 -->
        {{ $t("dapp.box2ST") }}
      </div>
      <div class="box">
        <div
          class="wl-item2"
          v-for="(item, index) in $t('home.box6ItemList')"
          :key="index"
        >
          <div class="img">
            <img
              :src="require(`../../assets/images/v4/home/box6${index + 1}.png`)"
              alt=""
            />
          </div>
          <div class="t">{{ item.title }}</div>
          <div class="link"></div>
          <div class="android">{{ item.subTitle }}</div>
          <div class="p pfont">
            {{ item.text }}
          </div>
          <!-- <div class="more">
            <span
              class="copy hash"
              @click="doCopy()"
              :data-clipboard-text="store.link.home.box6List[index]"
              >{{ $t("dapp.copy") }}</span
            >
            <a
              href="javascript:;"
              @click="uti.goPath(store.link.home.box6List[index])"
            >
              {{ item.ft }}
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-arrowRight"></use>
              </svg>
            </a>
          </div> -->
          <div class="more">
            <a
              href="javascript:;"
              @click="uti.goPath(store.link.home.box6List[index])"
            >
              {{ item.ft }}
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-arrowRight"></use>
              </svg>
            </a>
            <br />
            <span
              class="copy hash"
              v-if="store.link.home.box6Dapp[index]"
              @click="doCopy()"
              :data-clipboard-text="store.link.home.box6Dapp[index]"
            >
              <!-- {{ $t("dapp.copy") }} -->
              {{ store.link.home.box6Dapp[index] }}
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-copy"></use>
              </svg>
            </span>
          </div>
        </div>
        <div class="zw"></div>
      </div>
    </div>
  </div>
</template>
<script>
import Store from "../../store";
import Clipboard from "clipboard";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
  methods: {
    doCopy() {
      // console.log(cp);
      var clipboard = new Clipboard(".hash");
      // console.log(clipboard);
      clipboard.on("success", () => {
        this.$message.success("success");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        // 不支持复制

        this.$message.error("error");
        // 释放内存
        clipboard.destroy();
      });
    },
  },
};
</script>
<style lang="less" scoped>
.dapp-box {
  padding-top: 100px;
  padding-bottom: 250px;
  background-image: url("../../assets/images/v4/home/box2Bg.png"),
    url("../../assets/images/v4/home/box6Ft.png");
  background-repeat: no-repeat;
  background-position: 0 60%, right 100%;
  .title {
    font-size: 70px;
    font-weight: bold;
    color: #333333;
  }
  .gang {
    width: 60px;
    height: 7px;
    margin-top: 17px;
    background: linear-gradient(90deg, #015eea, #00c0fa);
  }
  .subTitle {
    height: 26px;
    font-size: 24px;
    font-weight: 500;
    color: #999999;
    margin-top: 38px;
  }
  .box {
    margin-top: 100px;
    // background-color: pink;
    width: 100%;
    // height: 500px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .wl-item2 {
      padding-bottom: 140px;
      margin-right: 20px;
      transition: 0.5s;
      // background: none;
      // background: linear-gradient(-45deg, #fff, #fff);
      .android {
        font-size: 14px;
        margin-top: 5px;
        font-weight: 400;
        color: #cccccc;
      }
      .link {
        margin-bottom: 0px;
      }
      .p {
        margin-top: 15px;
      }

      .more {
        .copy {
          margin-right: 20px;
          margin-top: 20px;
          display: block;
          opacity: 0.5;
        }
        cursor: pointer;
      }
    }
    .zw {
      width: 446px;
      flex: 1;
    }
    .wl-item2:hover {
      background: #015eea96;
      // background: linear-gradient(-45deg, #015eea, #00c0fa);
      color: #ffffff;
      position: relative;
      .t {
        color: #ffffff;
      }
      .link {
        background-color: #fff;
      }
      .android {
        color: #ffffff;
      }
      .p {
        color: #ffffff;
      }
      .more {
        color: #ffffff;
        a {
          color: #ffffff;
        }
      }
      .url {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}
</style>
