<template>
  <div class="wl-dapp">
    <dapp-box-1></dapp-box-1>
    <dapp-box-2></dapp-box-2>
  </div>
</template>
<script>
import dappBox1 from "./dapp-box1.vue";
import DappBox2 from "./dapp-box2.vue";
export default {
  components: { dappBox1, DappBox2 },
  data() {
    return {};
  },
};
</script>
